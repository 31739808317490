module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frontend Video Tips","short_name":"FrontTips","start_url":"/","background_color":"#181824","theme_color":"#c21aa5","theme_color_in_head":false,"display":"minimal-ui","icon":"src/images/favicons/icon.png","icons":[{"src":"src/images/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"10334cd417a6bc70b5c21abce68e218b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://front.tips","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
